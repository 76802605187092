import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  { name: 'getAllGroups', url: `${environment.torontoApiUrl}/variable-groups`, method: 'get'},
  { name: 'getGroup', url: `${environment.torontoApiUrl}/variable-groups/{id}`, method: 'get'},
  { name: 'saveGroup', url: `${environment.torontoApiUrl}/variable-groups`, method: 'post' },
  { name: 'updateGroup', url: `${environment.torontoApiUrl}/variable-groups/{id}`, method: 'put' },
  { name: 'deleteGroup', url: `${environment.torontoApiUrl}/variable-groups/{id}`, method: 'delete', notCancel: true },

  { name: 'getTicketSubjects', url: `${environment.torontoApiUrl}/ticket-types`, method: 'get' },
  { name: 'getTicketStatuses', url: `${environment.torontoApiUrl}/ticket-statuses`, method: 'get' },
  { name: 'getTicketStages', url: `${environment.sfApiUrl}/tickets-stages`, method: 'get' },
  { name: 'getTrackingStatuses', url: `${environment.torontoApiUrl}/delivery-statuses`, method: 'get' },
  { name: 'getCarriers', url: `${environment.torontoApiUrl}/carriers`, method: 'get'},
  { name: 'getCountries', url: `${environment.torontoApiUrl}/countries`, method: 'get'},

  { name: 'getAllRules', url: `${environment.torontoApiUrl}/ticket-rules`, method: 'get' },
  { name: 'getRule', url: `${environment.torontoApiUrl}/ticket-rules/{id}`, method: 'get' },
  { name: 'saveRule', url: `${environment.torontoApiUrl}/ticket-rules`, method: 'post' },
  { name: 'updateRule', url: `${environment.torontoApiUrl}/ticket-rules/{id}`, method: 'put' },
  { name: 'deleteRule', url: `${environment.torontoApiUrl}/ticket-rules/{id}`, method: 'delete' },
  { name: 'getExportRule', url: `${environment.torontoApiUrl}/rules-export`, method: 'get' },
  { name: 'duplicateRule', url: `${environment.torontoApiUrl}/ticket-rules/{id}/clone`, method: 'post' },
  { name: 'getSubStatuses', url: `${environment.torontoApiUrl}/ticket-sub-statuses`, method: 'get' },
  { name: 'getConditions', url: `${environment.torontoApiUrl}/conditions`, method: 'get' }
]

export default class TicketManagementService {
  constructor (context) {
    this.context = context
  }
  async callService (name, queryParams = {}, params = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params)
  }
  
  async callMultipleServices (callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }
  
  isArray (type) {
    return ['number-splitted', 'text-splitted', 'text', 'text-large', 'list'].includes(type)
  }

  validateNullVariable (group) {
    const isArray = this.isArray(group.type)
    if (!group.values) {
      // Valor del grupo es nulo
      return 'nullable'
    } else if (group.type === 'number') {
      // Variable no válida
      return 'number'
    } else if (isArray && group.values.length > 0) {
      // Variable es tipo arreglo y tiene valores
      return 'array'
    } else if ((!!group.values.from || group.values.from === 0) && !!group.values.to) {
      // Variable es tipo rango y tiene valores en from y to
      return 'range'
    } else if (isArray && group.values.length === 0) {
      // Variable es tipo arreglo y no tiene valores
      return 'nullable'
    } else if (!group.values.from && !group.values.to) {
      // Variable es tipo rango y no tiene valores en from ni to
      return 'nullable'
    } else {
      // Variable no válida
      return 'invalid'
    }
  }
  
  formatSelect (data, id) {
    // Formatea los elementos de tipo lista obtenidos desde servicio
    return (data.length > 0 ? data.map(element => {
      switch (id) {
      case 'delivery_status':
        return {...element, _id: element.id, text: element.name}
      case 'ticket_type':
        return {...element, _id: element.id, id: element.code, text: element.name}
      case 'ticket_status':
      case 'ticket_sub_status':
      case 'sometime_status':
        return {...element, _id: element.id, id: element.code, text: element.complete_name}
      case 'commune':
        return {...element, _id: element.codigo, id: element.codigo, text: element.nombre}
      case 'commune_drop':
        return {...element, _id: element.codigo, id: element.codigo, text: element.nombre}
      case 'carriers':
        return {...element, _id: element.id, id: element.code, text: element.name}
      case 'country':
        return {...element, _id: element.id, id: element.code, text: element.name}
      default:
        return {text: 'Otro'}
      }
    }) : []).sort((a, b) => {
      switch (id) {
      case 'ticket_status':
      case 'ticket_sub_status':
        return a.ticket_stage_code > b.ticket_stage_code ? -1 : 1
      default:
        return 1
      }
    })
  }

  getTextOperator (operator) {
    switch (operator) {
    case 'in':
      return 'cuyos valores sean iguales a'
    case 'in-like':
    case 'includes':
      return 'que contengan'
    case 'not-in':
      return 'diferentes de'
    case 'in-range':
      return 'que se encuentren en el rango'
    case 'out-range':
      return 'que se encuentren fuera del rango'
    case '<':
      return 'menor a'
    case '>':
      return 'mayor a'
    case '<=':
      return 'menor o igual a'
    case '>=':
      return 'mayor o igual a'
    case '==':
      return 'igual a'
    case 'is':
      return 'cuyo valor es'
    case 'in-or': 
      return 'Incluye al menos uno'
    }
  }
}